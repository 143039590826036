<template>
  <div>
    <div class="modalPopup" id="output_product_modal">
      <div class="modal_header">
        <h3 class="title">출고제품 선택</h3>
        <button class="modal_close" @click="$emit('onclose')"></button>
      </div>
      <div class="modal_body">
        <div class="input_search">
          <input
            type="text"
            placeholder="제품 검색"
            :value="search_value"
            @input="typing($event)"
          />
          <button>
            <i class="fa fa-search"></i>
          </button>
        </div>
        <div class="modal_search_opt"></div>
        <div class="mes_tbl_wrap">
          <table
            class="mes_tbl"
            :class="{ expire_date: systemCompany.expire_yn }"
          >
            <colgroup>
              <col
                v-for="(n, index) in systemCompany.expire_yn ? 7 : 6"
                :key="index"
              />
              >
            </colgroup>
            <thead>
              <tr>
                <th>제품명</th>
                <th>규격</th>
                <th>납품처</th>
                <th>Lot번호</th>
                <th>제품식별표</th>
                <th v-if="systemCompany.expire_yn">유통기한</th>
                <th>사용가능 재고</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in filteredList"
                :key="item.id"
                @click="selectedIndex = index"
                @dblclick="submit()"
                :class="{ active: selectedIndex == index }"
              >
                <td class="text_left">
                  {{ findInfoFromId(products, item.product_id).name }}
                </td>
                <td class="text_left">
                  {{ findInfoFromId(products, item.product_id).standard }}
                </td>
                <td class="text_left">
                  {{
                    item.company_label != undefined
                      ? item.company_label
                      : item.lot_company_id == null
                      ? '자체생산'
                      : item.sales_company_id != null
                      ? findInfoFromId(companys, item.lot_company_id).name
                      : `${
                          findInfoFromId(companys, item.lot_company_id).name
                        }_재고`
                  }}
                </td>
                <td>{{ `L${item.product_ident}` }}</td>
                <td class="text_right">
                  {{ `P${makeProductBarcode(item)}` }}
                </td>
                <td v-if="systemCompany.expire_yn">
                  {{ item.expire_date }} 까지
                </td>
                <td class="text_right">
                  <b>{{ $makeComma(item.quantity) }}</b
                  >{{
                    ` ${
                      findInfoFromId(
                        units,
                        findInfoFromId(products, item.product_id).stock_unit_id,
                      ).name
                    }`
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal_footer">
        <div class="warning" v-show="error_text != ''">
          {{ error_text }}
        </div>
        <button type="button" class="btn_sub2" @click="submit()">
          선택
        </button>
      </div>
    </div>
    <div class="modal_layer" @click="$emit('onclose')"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerMixin from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';
import FetchMixin from '@/mixins/fetch';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
import { getExpireDate, yyyymmdd } from '@/utils/func';

export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  mixins: [SpinnerMixin, ModalMixin, FetchMixin, DRAG_MODAL_MIXIN],
  data() {
    return {
      error_text: '',
      selectedIndex: -1,
      search_value: '',
    };
  },
  computed: {
    ...mapGetters({
      //   sales: 'getSales',
      //   sales_type: 'getSalesType',
      //   plan_sales_type: 'getPlanSalesType',
      systemCompany: 'getSystemCompany',
      companys: 'getCompany',
      products: 'getProduct',
      units: 'getUnitCodes',
    }),
    refineList() {
      const listTemp = this.lodash.clonedeep(this.list);
      return listTemp.map(x => {
        const products = this.findInfoFromId(this.products, x.product_id);
        x.expire_date = yyyymmdd(
          new Date(
            getExpireDate(
              new Date(x.create_time),
              products.expire_year,
              products.expire_month,
              products.expire_day,
            ),
          ),
        );
        return x;
      });
      // .sort((a, b) => new Date(a.create_time) - new Date(b.create_time));
    },
    filteredList() {
      const Hangul = require('hangul-js');
      const refine_list = this.lodash.clonedeep(this.refineList);
      return refine_list.filter(
        x =>
          this.findInfoFromId(this.products, x.product_id).name.includes(
            this.search_value,
          ) ||
          Hangul.d(this.findInfoFromId(this.products, x.product_id).name, true)
            .map(x => x[0])
            .join('')
            .includes(this.search_value),
      );
    },
  },
  methods: {
    submit() {
      if (this.selectedIndex == undefined || this.selectedIndex < 0) {
        this.error_text = `테이블의 행을 클릭하여 출고할 제품을 선택하여주십시오.`;
        return;
      }
      console.log(this.list[this.selectedIndex]);
      this.$emit('onselect', this.list[this.selectedIndex]);
    },
    makeProductBarcode(item) {
      if (item.create_time != undefined) {
        const date = item.create_time.replace(/-/gi, '').substr(0, 8);
        const product_control_id = item.id;
        const fix5 = ('00000' + product_control_id).substr(-5);
        item.barcode_num = date + fix5;
        return date + fix5;
      }
    },
    typing(e) {
      this.search_value = e.target.value.trim();
      this.selectedIndex = -1;
    },
  },
  async created() {
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY');
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT');
    }
  },
};
</script>

<style></style>
